<template>
  <div class="card">
    <div class="card-header">
      <a-button class="mr-2" @click="showModalNewProgramDevelopers" :disabled="!$auth.isLoggedIn()">
        <template #icon><i class="fa fa-plus" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="(selectedRowKeys.length != 1)||(!$auth.isLoggedIn())"
        @click="editEntity(selectedRows)"
      >
        <template #icon><i class="fa fa-pencil" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="(selectedRowKeys.length == 0)||(!$auth.isLoggedIn())"
        @click="deleteEntity(selectedRowKeys)"
      >
        <template #icon><i class="fa fa-trash" /></template>
      </a-button>
    </div>

    <a-table
      :dataSource="gridData"
      :columns="gridColumns"
      :scroll="scroll"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      rowKey="id"
      :class="gridData.length < 5 ? 'hide-native-scrollbar' : ''"
    />

    <a-modal
      width="100"
      v-model:visible="visibleProgramDevelopers"
      title="Разработчики"
      @ok="handleOkProgramDevelopers"
    >
      <a-form ref="ProgramDevelopersForm" :model="ProgramDevelopersFormState">
        <a-form-item label="Имя">
          <a-input
            v-model:value="ProgramDevelopersFormState.ProgramDevelopersName" :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
        <a-form-item label="Фамилия">
          <a-input
            v-model:value="ProgramDevelopersFormState.ProgramDevelopersSurname" :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
        <a-form-item label="Отчество">
          <a-input
            v-model:value="
              ProgramDevelopersFormState.ProgramDevelopersPatronymic
            " :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
        <a-form-item label="Учёная степень, учёное звание">
          <a-input
            v-model:value="
              ProgramDevelopersFormState.ProgramDevelopersAcademicDegree
            " :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
        <a-form-item label="Место работы">
          <a-input
            v-model:value="ProgramDevelopersFormState.ProgramDevelopersJob" :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
        <a-form-item label="Должность">
          <a-input
            v-model:value="ProgramDevelopersFormState.ProgramDevelopersPosition" :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
        <a-form-item label="Телефоны (с кодом города)">
          <a-input
            v-model:value="
              ProgramDevelopersFormState.ProgramDevelopersTelephone
            " :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
        <a-form-item label="E-mail">
          <a-input
            v-model:value="ProgramDevelopersFormState.ProgramDevelopersEmail" :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
        <a-form-item label="Почтовый адрес">
          <a-input
            v-model:value="
              ProgramDevelopersFormState.ProgramDevelopersMailingAddress
            " :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
        <a-form-item label="Факс">
          <a-input
            v-model:value="ProgramDevelopersFormState.ProgramDevelopersFax" :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "ProgramDevelopers",
  props: {
    SampleProgramID: { 
      type: Array
    },
    scroll: {
      type: Object,
      default () {
        return {
          x: 'calc(100%)',
          y: '100%'
        }
      }
    }
  },
  data() {
    return {
      gridColumns: [
        { dataIndex: "id", title: "ID", width: 80 },
        //{ dataIndex: "spId", title: "SPID" },
        { dataIndex: "surname", title: "Фамилия" },
        { dataIndex: "name", title: "Имя" },
        { dataIndex: "patronymic", title: "Отчество" },
        { dataIndex: "academicDegree", title: "Ученая степень" },
        { dataIndex: "job", title: "Работа" },
        { dataIndex: "position", title: "Должность" },
        { dataIndex: "telephone", title: "Телефон" },
        { dataIndex: "email", title: "Email" },
        { dataIndex: "mailingAddress", title: "Почтовый адрес" },
        { dataIndex: "fax", title: "Факс" },
      ],
      gridData: [],
      visibleProgramDevelopers: false,
      thisProgramDevelopers: [],
      ProgramDevelopersFormState: {
        ProgramDevelopersID: undefined,
        ProgramDevelopersSPID: undefined,
        ProgramDevelopersSurname: undefined,
        ProgramDevelopersName: undefined,
        ProgramDevelopersPatronymic: undefined,
        ProgramDevelopersAcademicDegree: undefined,
        ProgramDevelopersJob: undefined,
        ProgramDevelopersPosition: undefined,
        ProgramDevelopersTelephone: undefined,
        ProgramDevelopersEmail: undefined,
        ProgramDevelopersMailingAddress: undefined,
        ProgramDevelopersFax: undefined,
      },
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  watch: {
    SampleProgramID: {
      handler() {
        this.reload();
      },
      function() {},

      immediate: true,
    },
  },
  methods: {
    async reload() {
      if (this.SampleProgramID == undefined) {
        this.gridData = (await axios.get("program_developers")).data;
      } else {
        if (this.SampleProgramID == -1) {
          this.gridData = [];
        } else {
          this.gridData = (
            await axios.get(
              `program_developers/slaveobject/${this.SampleProgramID}`
            )
          ).data;
        }
      }
    },
    rowClick(record, index) {
      return {
        onClick: () => {
          console.log(record, index, 1111);
          this.thisProgramDevelopers = record;
          this.ProgramDevelopersFormState.ProgramDevelopersID = record.id;
          this.ProgramDevelopersFormState.ProgramDevelopersSPID = record.spId;
          this.ProgramDevelopersFormState.ProgramDevelopersSurname =
            record.surname;
          this.ProgramDevelopersFormState.ProgramDevelopersName = record.name;
          this.ProgramDevelopersFormState.ProgramDevelopersPatronymic =
            record.patronymic;
          this.ProgramDevelopersFormState.ProgramDevelopersAcademicDegree =
            record.academicDegree;
          this.ProgramDevelopersFormState.ProgramDevelopersJob = record.job;
          this.ProgramDevelopersFormState.ProgramDevelopersPosition =
            record.position;
          this.ProgramDevelopersFormState.ProgramDevelopersTelephone =
            record.telephone;
          this.ProgramDevelopersFormState.ProgramDevelopersEmail = record.email;
          this.ProgramDevelopersFormState.ProgramDevelopersMailingAddress =
            record.mailingAddress;
          this.ProgramDevelopersFormState.ProgramDevelopersFax = record.fax;
          console.log(this.thisProgramDevelopers);
          this.showModalProgramDevelopers();
        },
      };
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    showModalProgramDevelopers() {
      this.visibleProgramDevelopers = true;
    },
    clearForm() {
      this.ProgramDevelopersFormState.ProgramDevelopersID = undefined;
      this.ProgramDevelopersFormState.ProgramDevelopersSPID = undefined;
      this.ProgramDevelopersFormState.ProgramDevelopersSurname = undefined;
      this.ProgramDevelopersFormState.ProgramDevelopersName = undefined;
      this.ProgramDevelopersFormState.ProgramDevelopersPatronymic = undefined;
      this.ProgramDevelopersFormState.ProgramDevelopersAcademicDegree =
        undefined;
      this.ProgramDevelopersFormState.ProgramDevelopersJob = undefined;
      this.ProgramDevelopersFormState.ProgramDevelopersPosition = undefined;
      this.ProgramDevelopersFormState.ProgramDevelopersTelephone = undefined;
      this.ProgramDevelopersFormState.ProgramDevelopersEmail = undefined;
      this.ProgramDevelopersFormState.ProgramDevelopersMailingAddress =
        undefined;
      this.ProgramDevelopersFormState.ProgramDevelopersFax = undefined;
    },
    showModalNewProgramDevelopers() {
      this.clearForm();

      if (this.SampleProgramID != undefined)
        this.ProgramDevelopersFormState.ProgramDevelopersSPID =
          this.SampleProgramID;

      this.visibleProgramDevelopers = true;
    },
    async handleOkProgramDevelopers() {
      console.log("program_developers");
      this.visibleProgramDevelopers = false;
      if (this.ProgramDevelopersFormState.ProgramDevelopersName !== undefined) {
        if (this.ProgramDevelopersFormState.ProgramDevelopersID == undefined) {
          try {
            await axios.post("program_developers", {
              name: this.ProgramDevelopersFormState.ProgramDevelopersName,
              surname: this.ProgramDevelopersFormState.ProgramDevelopersSurname,
              spid: this.ProgramDevelopersFormState.ProgramDevelopersSPID,
              patronymic:
                this.ProgramDevelopersFormState.ProgramDevelopersPatronymic,
              academicDegree:
                this.ProgramDevelopersFormState.ProgramDevelopersAcademicDegree,
              job: this.ProgramDevelopersFormState.ProgramDevelopersJob,
              position:
                this.ProgramDevelopersFormState.ProgramDevelopersPosition,
              telephone:
                this.ProgramDevelopersFormState.ProgramDevelopersTelephone,
              email: this.ProgramDevelopersFormState.ProgramDevelopersEmail,
              mailingAddress:
                this.ProgramDevelopersFormState.ProgramDevelopersMailingAddress,
              fax: this.ProgramDevelopersFormState.ProgramDevelopersFax,
            });
          } finally {
            this.clearForm();
            await this.reload();
          }
        } else {
          console.log("program_developers");
          console.log({
            id: this.ProgramDevelopersFormState.ProgramDevelopersID,
            name: this.ProgramDevelopersFormState.ProgramDevelopersName,
          });
          try {
            await axios.put("program_developers", {
              id: this.ProgramDevelopersFormState.ProgramDevelopersID,
              name: this.ProgramDevelopersFormState.ProgramDevelopersName,
              surname: this.ProgramDevelopersFormState.ProgramDevelopersSurname,
              spid: this.ProgramDevelopersFormState.ProgramDevelopersSPID,
              patronymic:
                this.ProgramDevelopersFormState.ProgramDevelopersPatronymic,
              academicDegree:
                this.ProgramDevelopersFormState.ProgramDevelopersAcademicDegree,
              job: this.ProgramDevelopersFormState.ProgramDevelopersJob,
              position:
                this.ProgramDevelopersFormState.ProgramDevelopersPosition,
              telephone:
                this.ProgramDevelopersFormState.ProgramDevelopersTelephone,
              email: this.ProgramDevelopersFormState.ProgramDevelopersEmail,
              mailingAddress:
                this.ProgramDevelopersFormState.ProgramDevelopersMailingAddress,
              fax: this.ProgramDevelopersFormState.ProgramDevelopersFax,
            });
          } finally {
            this.clearForm();
            await this.reload();
          }
        }
      } else {
        return;
      }
    },
    async deleteEntity(selectedRowKeys) {
      console.log(selectedRowKeys);
      try {
        await axios.delete("program_developers", {
          headers: { "Content-Type": "application/json; charset=utf-8" },
          data: selectedRowKeys,
        });
      } finally {
        selectedRowKeys = undefined;
        await this.reload();
      }
    },
    async editEntity(selectedRows) {
      console.log(selectedRows);
      this.ProgramDevelopersFormState.ProgramDevelopersID = selectedRows[0].id;
      this.ProgramDevelopersFormState.ProgramDevelopersSPID =
        selectedRows[0].spId;
      this.ProgramDevelopersFormState.ProgramDevelopersName =
        selectedRows[0].name;
      this.ProgramDevelopersFormState.ProgramDevelopersSurname =
        selectedRows[0].surname;
      this.ProgramDevelopersFormState.ProgramDevelopersPatronymic =
        selectedRows[0].patronymic;
      this.ProgramDevelopersFormState.ProgramDevelopersAcademicDegree =
        selectedRows[0].academicDegree;
      this.ProgramDevelopersFormState.ProgramDevelopersJob =
        selectedRows[0].job;
      this.ProgramDevelopersFormState.ProgramDevelopersPosition =
        selectedRows[0].position;
      this.ProgramDevelopersFormState.ProgramDevelopersTelephone =
        selectedRows[0].telephone;
      this.ProgramDevelopersFormState.ProgramDevelopersEmail =
        selectedRows[0].email;
      this.ProgramDevelopersFormState.ProgramDevelopersMailingAddress =
        selectedRows[0].mailingAddress;
      this.ProgramDevelopersFormState.ProgramDevelopersFax =
        selectedRows[0].fax;
      this.showModalProgramDevelopers();
    },
  },
  async created() {
    await this.reload();
  },
};
</script>